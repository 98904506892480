.Slider{width:100%;min-height:auto;position:relative;z-index:1;background:var(--white);padding-top:0!important;overflow:inherit!important}
.Slider>svg{position:absolute;bottom:0;left:0;z-index:2;filter:drop-shadow(0 -9px 6px rgb(var(--blackrgb)/.1))}
.Slider>div{z-index:initial}
.Slider .carousel-item img{height:100%;width:100%;object-fit:cover;}
.Slider .carousel-inner,.Slider .carousel-item{height:100%;}
.Slider .carousel-item{position:relative;height:100%;}

.Slider .carousel-item:after{position:absolute;content:'';top:0;left:0;width:100%;height:100%;background:rgb(var(--blackrgb)/.7)}
.Slider .carousel-item.active .carousel-caption .text-center,.Slider .carousel-item.active .carousel-caption .text-left,.Slider .carousel-item.active .carousel-caption .text-right{opacity:1;transform:translate(0);transition:all 1000ms ease;/*transition-delay:100ms*/}
.Slider .carousel-item .carousel-caption{z-index:1;position:absolute;bottom:auto;top:0;left:0;opacity:1;padding:0;right:auto;text-align:left;width:100%;height:calc(100% - 90px);display:flex-start;justify-content:center;align-items:center;color:var(--white)}
.Slider .carousel-item .carousel-caption p{color:var(--white)!important;font-size:18px}
.Slider .carousel-item .carousel-caption .title{line-height:130%!important;font-weight:600;color:var(--thm)!important;position:relative}
.Slider .carousel-item .carousel-caption .title span{font-weight:300}
.Slider .carousel-item .carousel-caption .text-center{transform:translateY(-90px);opacity:0}
.Slider .carousel-item .carousel-caption .text-center{justify-content:center}
.Slider .carousel-item .carousel-caption .text-center>div{text-align:center}
.Slider .carousel-item .carousel-caption .text-right{transform:translateX(90px);opacity:0}
.Slider .carousel-item .carousel-caption .text-right{justify-content:flex-end}
.Slider .carousel-item .carousel-caption .text-right>div{text-align:right}
.Slider .carousel-item .carousel-caption .text-left{transform:translateX(-90px);opacity:0}
.Slider .carousel-item .carousel-caption .text-left{justify-content:flex-start}
.Slider .carousel-item .carousel-caption .text-left>div{text-align:left}
.Slider .carousel-item.active .carousel-caption .text-center,.Slider .carousel-item.active .carousel-caption .text-left,.Slider .carousel-item.active .carousel-caption .text-right{opacity:1;transform:translate(0);transition:all 1000ms ease;/*transition-delay:100ms*/}
.Slider .carousel-indicators{bottom:5%}
.Slider .carousel-indicators>button{height:10px;width:10px;background:var(--thm);border:none;border-radius:9px;margin:0 7px;opacity:.3;box-shadow:0 2px 3px rgb(var(--blackrgb)/.1);transition:all .5s}
.Slider .carousel-indicators>button.active{opacity:1;width:30px}
.Slider .carousel-control-next,.Slider .carousel-control-prev{width:8%}

@media (min-width:992px){.Slider>div{height:85vh;min-height:550px;}
/*.Slider .carousel-item .carousel-caption .title, .Ibanner .title{font-size:55px;}*/}
@media (max-width:767px){.Slider>div{height:480px;}
.Slider .carousel-item picture img{position:absolute;}
.Slider .carousel-item .carousel-caption{position:inherit;padding:50px 9px 50px}
.Slider .carousel-item .carousel-caption .title, .Ibanner .title{font-size:48px;}
.Slider .carousel-item .carousel-caption .text-start,.Slider .carousel-item .carousel-caption .text-end{justify-content:center;text-align:center!important}}
@media (max-width:574px){.Slider .carousel-item .carousel-caption .title, .Ibanner .title{font-size:41px;}}
@media (max-width:380px){.Slider .carousel-item .carousel-caption .title, .Ibanner .title{font-size:34px;}}
main .text-center.mt-5.mb-5:first-child{height:70vh;min-height:600px;}

.Sec1>svg,.Sec3>svg,.Sec4>svg{position:absolute;bottom:0;left:0;z-index:-1;opacity:.1;fill:var(--thm);width:100%;height:auto}
.Sec1 img{width:100%; max-width:550px; height:auto;border-radius:0 90px 0 90px}

.Sec2 .CValues{column-gap:24px;display:flex-start;flex-wrap:wrap;align-items:center;margin-top:60px!important}
.Sec2 .CValues li{padding:30px 20px;border-radius:15px;box-shadow:0 5px 15px rgb(var(--blackrgb)/.2);background:var(--white);width:calc(50% - 12px);margin-bottom:24px;transition:all .5s}
.Sec2 .CValues li:nth-child(odd){margin-top:-120px}
.Sec2 .CValues li>div{display:inline-block}
.Sec2 .CValues li>div *{margin:9px auto 0;display:block;width:100%}
.Sec2 .CValues li img{margin-top:0;height:90px}
.Sec2 .CValues li:hover{transform:translateY(-4px)}
@media (max-width:767px){.Sec2 .CValues{column-gap:16px;margin-top:70px!important;}
.Sec2 .CValues li{width:calc(50% - 8px);padding:20px 15px;}
.Sec2 .btn+.btn{margin-left:1rem!important;}
}
@media (max-width:420px){.Sec2 .btn+.btn{margin-left:0!important;}}

@media (min-width:992px){.Sec3 .bg-img{height:100%;width:42%;position:absolute;left:2%;top:0;z-index:1}
.Sec3 .bg-img{top:auto;bottom:180px;height:auto!important;width:18%}}
@media (min-width:1400px){.Sec3 .bg-img{height:100%}}
@media (max-width:992px){.Sec3 .bg-img{max-width:500px;display:flex-start;margin:0 auto}}
.Sec3>svg{fill:var(--white);bottom:auto;top:0;opacity:1;z-index:1}
.Sec3>*{z-index:2}
.Sec2,.Sec3{background:rgb(var(--thmrgb)/.1)}
.Sec3.Services .card:hover{transform:translateY(-5px);box-shadow:0 5px 20px rgb(var(--blackrgb)/.4)}
.MyCarousel .SerBlock{margin-bottom:15px;height:auto}

.Sec4>svg{width:100%;z-index:0;fill:#f3f3f3;opacity:1}
.Sec4 .blog .card{border:none;border-radius:0;background:none;margin-bottom:15px;/*min-width:calc(33.333% - 16px);max-width:calc(33.333% - 16px)*/}
.Sec4 .blog .card-header{border-radius:9px;height:250px;overflow:hidden;padding:0;border:none}
.Sec4 .blog .card-header img{height:100%;width:100%;object-fit:cover;transition:all .5s}
.Sec4 .blog .card-body{border-radius:9px;background:var(--white);width:calc(100% - 30px);margin:-20px auto 0;position:relative;z-index:1;box-shadow:0 5px 9px rgb(var(--blackrgb)/.2)}
.Sec4 .blog .card-body .badge{font-weight:400;padding:8px 15px;border-radius:15px;font-size:14px;color:var(--white)!important}
.Sec4 .blog .card-body h3{font-weight:400;font-size:18px;transition:all .5s}
.Sec4 .blog .card:hover .card-header img{transform:scale(1.1)}
.Sec4 .blog .card:hover h3{color:var(--black)}

.SerTab .nav-pills{display:grid;/*row-gap:0;height:88vh;*/overflow:auto;background:var(--white);box-shadow:0 0 9px rgb(var(--blackrgb)/.15);overflow:hidden;border-radius:9px}
.SerTab .nav-pills li{border-bottom:1px solid rgb(var(--blackrgb)/.1);}
.SerTab .nav-pills li a{display:flex;text-align:left;align-items:center;height:100%;border-radius:0; padding:9px 15px; transition:all .5s}
.SerTab .nav-pills li img{height:36px;width:36px;object-fit:contain; margin-right:12px;}
.SerTab .nav-pills li h3{margin:5px 0 0;font-size:15px;font-weight:400}
/*.SerTab .nav-pills li:last-child{border:none}*/
.SerTab .nav-pills li.active{position:sticky;top:0;}
.SerTab .nav-pills li a.active{background:var(--thm);border-radius:9px}
.SerTab .nav-pills li a.active h3{color:var(--white)!important;}
.SerTab .nav-pills li a.active img{filter:invert(1);}
.SerTab .tab-pane .ProBlock:after{position:absolute;right:-12px;width:1px;background:rgb(var(--blackrgb)/.1);content:'';top:0;height:100%}
.SerTab .tab-pane .ProBlock:hover:after{transform:translateY(5px);}
@media (min-width:1200px){.SerTab .tab-pane .SerBlock .card-header{height:230px;}
.SerTab .tab-pane .ProBlock{min-width:calc(100% - 0px);max-width:calc(100% - 0px);margin-top:6px;text-align:center;}
.SerTab .tab-pane .MyCarousel>div{min-width:calc(25% - 19px);max-width:calc(25% - 19px)}}

.testi .testitext{position:relative;padding:5px 40px;display:flex-start;align-items:flex-start;font-size:18px!important}
.testi .testitext p,.testi .testitext>div{font-size:16px!important;line-height:160%!important;}
.testi .testitext p *,.testi .testitext>div *{color:rgb(var(--blackrgb)/.8)!important;font-family:var(--font)!important;}
.testi .testitext:after,.testi .testitext:before{content:'';/*position:absolute;left:0;top:0;*/display:inline-block;min-height:38px;min-width:38px;background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.74 151.22"><path d="M125.27,151.2q-7.22,0-5.92-8.1l15-94.5q5-31.49,32.89-48.6h31.51c7.15.1,8,9.53,2,13.94,0,0-21.31,11.47-25,34.66L170.9,79.2h23.4c4.79,0,6.82,2.41,6.06,7.2l-9,56.7q-1.29,8.1-8.48,8.1Z"/><path d="M6.11,151.22c-4.81,0-6.77-2.7-5.92-8.09l15-94.5q5-31.5,32.9-48.6h31.5c7.15.09,7.94,9.52,2,13.93,0,0-21.32,11.48-25,34.67L51.75,79.22h23.4c4.8,0,6.82,2.41,6.06,7.2l-9,56.71c-.85,5.39-3.69,8.09-8.48,8.09Z"/></svg>') center no-repeat;margin-right:9px}
.testi .testitext:after{margin-right:0;margin-left:9px;transform:scaleX(-1)}
.testi img{height:80px!important;width:80px!important;object-fit:cover;border-radius:50%;margin:0 auto}
.testi span{color:var(--black)}
@media (max-width:767px){.testi .testitext:after,.testi .testitext:before{min-height:30px;min-width:30px}
.testi .testitext{padding:5px 0}
.testi .testitext p{font-size:16px!important}}