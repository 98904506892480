.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}

.BlogSec4 .blog .card{border:none;border-radius:0;background:none;margin-bottom:15px}
.BlogSec4 .blog .card-header{border-radius:9px;height:250px;overflow:hidden;padding:0;border:none}
.BlogSec4 .blog .card-header img{height:100%;width:100%;object-fit:cover;transition:all .5s}
.BlogSec4 .blog .card-body{border-radius:9px;background:var(--white);width:calc(100% - 30px);margin:-20px auto 0; padding:15px!important; position:relative;z-index:1;box-shadow:0 5px 9px rgb(var(--blackrgb)/.2)}
.BlogSec4 .blog .card-body .badge{font-weight:400;padding:8px 15px;border-radius:15px;font-size:14px;color:var(--white)!important}
.BlogSec4 .blog .card-body h3{font-weight:400;font-size:18px;transition:all .5s}
.BlogSec4 .blog .card:hover .card-header img{transform:scale(1.1)}
.BlogSec4 .blog .card:hover h3{color:var(--black)}

body>main{overflow:inherit!important}
.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}

.Sec4 .blog .card{border:none;border-radius:0;background:none;margin-bottom:15px}
.Sec4 .blog .card-header{border-radius:9px;height:250px;overflow:hidden;padding:0;border:none}
.Sec4 .blog .card-header img{height:100%;width:100%;object-fit:cover;transition:all .5s}
.Sec4 .blog .card-body{border-radius:9px;background:var(--white);width:calc(100% - 30px);margin:-20px auto 0;position:relative;z-index:1;box-shadow:0 5px 9px rgb(var(--blackrgb)/.2)}
.Sec4 .blog .card-body .badge{font-weight:400;padding:8px 15px;border-radius:15px;font-size:14px;color:var(--white)!important}
.Sec4 .blog .card-body h3{font-weight:400;font-size:18px;transition:all .5s}
.Sec4 .blog .card:hover .card-header img{transform:scale(1.1)}
.Sec4 .blog .card:hover h3{color:var(--black)}