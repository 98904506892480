.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}

.gallery.card{border-radius:9px;border:none;overflow:hidden;}
.gallery.card .card-body img{height:100%;width:100%;object-fit:cover;border-radius:0!important}
.gallery.card>div{border:none;}
.gallery.card .card-footer{position:absolute;top:0;left:0;height:100%;width:100%;background:rgb(var(--blackrgb)/.7);z-index:1;opacity:0; padding:20px; display:grid;place-content:end start;place-items:flex-start;transition:all .5s}
.gallery.card .card-footer span.imgs{background:rgb(var(--blackrgb)/.8);padding:3px 9px;border-radius:5px;color:var(--white);font-size:13px}
.gallery.card:hover .card-footer{opacity:1}
.gallery{height:250px;}
.gallery:after{background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.62 107.62" fill="none" stroke="%23fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5px"><line x1="53.81" y1="82.06" x2="53.81" y2="25.56"/><line x1="82.06" y1="53.81" x2="25.56" y2="53.81"/><circle cx="53.81" cy="53.81" r="51.31"/></svg>') center/3em auto no-repeat rgb(var(--blackrgb)/.5);content:'';position:absolute;height:100%;width:100%;left:0;top:0;transform:scale(.5);opacity:0;transition:all .5s;}
.gallery:hover:after{opacity:1;transform:scale(1);}
@media (max-width:450px){.gallery.card{height:180px}}