.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}
@media (min-width:1400px){.Services .list>div{width:20%}}
@media (max-width:420px){.Services .list>div{width:100%}}


/**** Detail ****/
.SDInnerHead{padding:90px 0}
.SDInnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.SDInnerHead>div{position:relative;z-index:1}
.SDInnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}
.TbSlide .SerBlock{margin-bottom:15px;height:auto}
@media (min-width:1200px){.TbSlide .SerBlock{min-width:calc(25% - 18px)}}