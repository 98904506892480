.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}
.grid-wrapper{column-count:2;column-gap:24px;margin:auto;width:100%}
.testiHome{border:none!important;box-shadow:none!important;transform:translateY(0)!important;}
.testi .testibox{display:inline-block;width:100%;margin-bottom:30px;border-radius:9px;padding:9px; border:1px solid rgb(var(--blackrgb)/.08); transition:all .5s}
.testi .testitext{position:relative;padding:9px 1px;display:inline-flex;align-items:flex-start;justify-content:center;width:100%;}
.testi .testitext:after,.testi .testitext:before{content:'';/*position:absolute;left:0;top:0;*/display:inline-block;min-height:32px;min-width:32px;background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.74 151.22"><path d="M125.27,151.2q-7.22,0-5.92-8.1l15-94.5q5-31.49,32.89-48.6h31.51c7.15.1,8,9.53,2,13.94,0,0-21.31,11.47-25,34.66L170.9,79.2h23.4c4.79,0,6.82,2.41,6.06,7.2l-9,56.7q-1.29,8.1-8.48,8.1Z"/><path d="M6.11,151.22c-4.81,0-6.77-2.7-5.92-8.09l15-94.5q5-31.5,32.9-48.6h31.5c7.15.09,7.94,9.52,2,13.93,0,0-21.32,11.48-25,34.67L51.75,79.22h23.4c4.8,0,6.82,2.41,6.06,7.2l-9,56.71c-.85,5.39-3.69,8.09-8.48,8.09Z"/></svg>') center no-repeat;margin-right:9px;opacity:.1}
.testi .testitext:after{margin-right:0;margin-left:9px;transform:scaleX(-1)}
.testi img{height:60px!important;width:60px!important;object-fit:cover;border-radius:50%;margin:0 auto}
.testi span{color:var(--black)}
.testi .testibox:hover{box-shadow:0 5px 9px rgb(var(--blackrgb)/.2);transform:translateY(-5px);}
@media(max-width:767px){.grid-wrapper{column-count:1}
.testi .testitext:after,.testi .testitext:before{min-height:24px;min-width:24px}
.testi .testitext{padding:5px 0}
.testi .testitext p{font-size:15px!important}}