.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}
.AboutSec1>svg{position:absolute;bottom:0;left:0;z-index:-1;opacity:.1;fill:var(--thm);width:100%;height:auto}
.Sec2 .CValues{column-gap:24px;display:flex;flex-wrap:wrap;align-items:center;margin-top:60px!important}
.Sec2 .CValues li{padding:30px 20px;border-radius:15px;box-shadow:0 5px 15px rgb(var(--blackrgb)/.2);background:var(--white);width:calc(50% - 24px);margin-bottom:24px;transition:all .5s}
.Sec2 .CValues li:nth-child(odd){margin-top:-120px}
.Sec2 .CValues li>div{display:inline-block}
.Sec2 .CValues li>div *{margin:9px auto 0;display:block;width:100%}
.Sec2 .CValues li img{margin-top:0;height:90px}
.Sec2 .CValues li:hover{transform:translateY(-4px)}
.Sec2{background:rgb(var(--thmrgb)/.1)}
/* footer{margin-top:0!important} */
/* @media (max-width:992px){.Sec2{padding-bottom:120px}} */
@media (max-width:767px){.AboutSec1{display:flex;margin:0 auto}}