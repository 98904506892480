:root{--thm:#18c5da;--thmrgb:24 197 218;--thm1:#1a1a1a;--thmrgb1:26 26 26;--white:#fff;--whitergb:255 255 255;--black:#000;--blackrgb:0 0 0;--font:'Montserrat',sans-serif;--font1:'Poppins',sans-serif}

*{outline:0;box-sizing:border-box}
html{scroll-behavior:smooth}
/*html{scroll-snap-type:y mandatory;-webkit-overflow-scrolling:touch}
.sectionb{scroll-snap-align:start;scroll-snap-stop:always;flex-direction:column;scroll-margin:70px}
.Slider.sectionb{scroll-margin:700px}*/
::-webkit-scrollbar{width:5px;height:0;background-color:rgb(var(--blackrgb)/0)}
::-webkit-scrollbar-thumb{background-color:rgb(var(--blackrgb)/.4);border-radius:2px}
::-moz-scrollbar{width:9px;height:0;background-color:rgb(var(--blackrgb)/0)}
::-moz-scrollbar-thumb{background-color:rgb(var(--blackrgb)/.4);border-radius:2px}
::-o-scrollbar{width:9px;height:0;background-color:rgb(var(--blackrgb)/0)}
::-o-scrollbar-thumb{background-color:rgb(var(--blackrgb)/.4);border-radius:2px}
body{font-family:var(--font)!important;font-size:1rem;color:var(--black);transition:background-color 1s ease}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}
/* Firefox */
input[type=number]{-moz-appearance:textfield}

::placeholder{color:rgb(var(--blackrgb)/.4)!important}
:-ms-input-placeholder{color:rgb(var(--blackrgb)/.2)!important}
::-ms-input-placeholder{color:rgb(var(--blackrgb)/.2)!important}
input:-internal-autofill-selected{background-color:var(--white)!important;background-image:inherit!important}

hr{border:none;border-bottom:1px solid rgb(var(--blackrgb)/.4)}
.btn>*{transition:all .5s}
.btn-thm,.btn-thm1,.btn-thm2,.btn-thm3{font-weight:400;font-size:16px;min-width:99px;line-height:48px;display:inline-flex;justify-content:center;align-items:center;top:0;border-radius:8px;padding:0 30px;margin-top:15px;color:var(--white)!important;/*overflow:hidden;*/position:relative;transition:all .5s}
.btn-thm{background:var(--thm)!important;border-color:var(--thm)!important}
.btn-thm1{background:var(--black)!important;color:var(--white)!important;border-color:var(--black)!important}
.btn-thm2,.btn-thm3{background:none!important;color:var(--black)!important;border-color:var(--black)!important}
.btn-thm3{color:var(--thm)!important;border:1px solid var(--thm)!important}

.btn-thm:hover,.btn-thm1:hover,.btn-thm2:hover,.btn-thm3:hover{transform:translateY(-3px);box-shadow:0 25px 20px -15px rgb(var(--blackrgb)/.5)}

.btn-sm{font-size:14px;line-height:32px;padding:0 15px}
.btn-lg{font-size:18px;line-height:55px;padding:0 40px}

.btn-thm img,.btn-thm1 img,.btn-thm2 img,.btn-thm3 img{height:20px!important;width:20px!important;margin-left:15px}
.btn-thm:hover i,.btn-thm1:hover i,.btn-thm2:hover i,.btn-thm3:hover i,.btn-thm:hover img,.btn-thm1:hover img,.btn-thm2:hover img,.btn-thm3:hover img{transform:translateY(0) translateX(4px)}
.btn-thm.white{background-color:var(--white)!important;border-color:var(--white)!important;color:var(--thm)!important}
.btn-thm.white:hover{color:var(--white)!important}
.btn-thm1.white{color:var(--white)!important;border-color:var(--white)!important}

.grey{background:#f3f3f3}
.LWhite{background:#ddd}

.Heading{font-size:36px;line-height:120%!important;margin-bottom:20px;font-family:var(--font1);font-weight:600;text-transform:uppercase}
.Heading span{font-weight:300}
.SubTitle{font-size:16px;display:inline-block;color:var(--white)!important;font-family:var(--font)!important;font-style:italic;position:relative;margin:0 0 9px;padding:2px 2rem;background:linear-gradient(90deg,transparent 0%,var(--thm) 25%,var(--thm) 75%,transparent 100%);min-width:150px}
.Heading+p.h5{font-weight:400}
.text-center .Heading{justify-content:center}
.text-center .Heading:after,.text-center .Heading:before{right:0;margin:0 auto}
.text-center .SubTitle{text-align:center}

.thm{color:var(--thm)!important}
.bgthm{background:var(--thm)!important}
.fillthm{fill:var(--thm)!important}
.thm1{color:var(--thm1)!important}
.bgthm1{background:var(--thm1)!important}
.fillthm1{fill:var(--thm1)!important}
.font{font-family:var(--font)!important}
.font1{font-family:var(--font1)!important}
.small,small{font-size:smaller}
a{text-decoration:none}
a:hover{color:var(--thm)}
p,ul li,ol li,.p{line-height:160%!important;font-weight:400;letter-spacing:0;color:var(--black)!important}
strong{font-weight:700!important}
p.lh-n,.lh-n{line-height:normal!important}
.row{margin-bottom:0}
.iframe iframe{width:100%;border:none}
.text-u{text-transform:uppercase}

.w-100,.w-75{height:auto}
.h-100{width:auto}
.dropdown-toggle:after,.dropdown-toggle span:after{content:"";border:none;border-bottom:1px solid;border-left:1px solid;height:8px;width:8px;transform:rotate(-45deg);transition:all .5s}

body.bg-dark{background:var(--thm2)!important}
body section:before,body section:after,body footer:before,body footer:after{content:'';position:absolute;z-index:-1}
body section{padding:80px 0;position:relative;overflow:hidden;}
body section.Home{padding:80px 0 0;position:relative;z-index:3}
body section .svg-shape,body section .svg-shape1{position:absolute;width:100%;top:0;z-index:-1}
body section .svg-shape path,body section .svg-shape1 path{fill:var(--white)}
body>main{overflow:hidden;display:block}
/*@media (min-width:992px){body>main{margin-top:-125px}}*/

ul:not(.browser-default)>li{list-style-type:none}
a{color:rgb(var(--blackrgb)/.9);-webkit-transition:all .5s;-moz-transition:all .5s;transition:all .5s;outline:0}
a:hover{color:var(--thm);outline:none!important;text-decoration:none!important}
a:focus{outline:none!important;text-decoration:none!important}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{line-height:150%!important;font-family:var(--font1)}
section{padding:0;margin:0}
section>div{position:relative;z-index:1}
section:after{content:attr(data-text)!important;font-size:9rem;color:rgb(var(--blackrgb)/.08);left:0;right:0;margin:0 auto;top:40px;text-align:center;font-weight:500;z-index:-1!important}
body.bg-dark section:after{color:rgb(var(--whitergb)/.08)}
body.bg-dark,body.bg-dark a{color:var(--white)}
body.bg-dark p,body.bg-dark .Heading span{color:rgb(var(--whitergb)/.7)!important;font-weight:300}
body.bg-dark .Heading:before{background:var(--white)}
body.bg-light{color:var(--black)}
aside{padding:50px 0}
::-moz-selection{text-shadow:none;color:var(--white);background:var(--thm)}
::selection{text-shadow:none;color:var(--white);background:var(--thm)}
img::-moz-selection{color:var(--white);background:0}
img::selection{color:var(--white);background:0}

@media only screen and (min-width:992px){.container{max-width:90%!important}}
@media only screen and (min-width:1400px){.container{max-width:1200px!important}}
@media only screen and (min-width:1600px){.container{max-width:1350px!important}}

.BackTop{position:fixed;bottom:20px;right:15px;display:none;color:var(--white);height:45px;width:45px;background:var(--thm);border-radius:50%;box-shadow:0 0 15px rgb(var(--whitergb)/.3)!important;z-index:9;transition:all .9s}
.BackTop span{display:grid;place-content:center;text-align:center;height:100%;width:90%;margin:0 auto}
.BackTop span i{width:16px;height:16px;border-left:2px solid var(--white);border-top:2px solid var(--white);transform:rotate(45deg);margin:0 auto}
.BackTop span span{font-size:8px;margin-top:-3px;line-height:8px}
.BackTop:hover{color:var(--white)}
@media (max-width:767px){.BackTop{height:60px;width:60px}}

header{position:relative;padding:8px 0;border-bottom:1px solid rgb(var(--blackrgb)/.1)}
header a{color:rgb(var(--whitergb)/.8)}
header a:hover{color:var(--white)}
header .icons{justify-content:start}
header .icons li{margin:0 5px!important;line-height:0!important}
header .icons li:first-child{margin-left:0!important}
header .icons img{height:21px!important;width:21px!important}

nav.menu{padding:0 0 0;height:99px;z-index:99}
nav.menu:not(.is-fixed) .st{display:grid;height:100%;width:100%;place-items:center}
nav .container>div{align-items:center;justify-content:space-between}
nav .logom{position:relative;z-index:4;display:flex;align-items:center}
nav .logom .logo{width:100%;display:block;max-width:90px;margin-bottom:0;padding:5px 0;}
nav .logom .logo img{height:100%;width:100%}
nav .MenuBar{position:relative;display:flex;align-items:center;justify-content:space-between}
nav.menu .MenuIcon,nav.menu .BottomSec{height:80px;background:transparent;padding:0 6px 0 0;margin-right:9px;border-radius:0;box-shadow:none!important;display:grid;place-content:center;transition:all .9s}
nav.menu .MenuIcon{z-index:3;border:none}
nav.menu .MenuIcon svg{height:22px;width:22px}
nav.menu .MenuIcon svg path{-webkit-transition:.3s;transition:.3s}
nav.menu .MenuIcon:not(.offcanvasClose) svg{/*fill:var(--black)!important;*/transform:scale(.8)}
nav.menu .MenuIcon:not(.offcanvasClose) svg path{opacity:0}
nav.menu .MenuIcon:not(.offcanvasClose) svg path.bar1{transform:rotate(45deg) translateY(-1px) translateX(5px);opacity:1}
nav.menu .MenuIcon:not(.offcanvasClose) svg path.bar2{transform:rotate(-45deg) translateY(-5px) translateX(-15px);opacity:1}
nav.menu .download-btn{display:flex;margin-left:.5%;white-space:nowrap;align-items:center;font-size:16px}
nav.menu .download-btn span{height:42px;width:42px;border-radius:50%;background:var(--black);padding:9px;;display:grid;place-content:center;margin-right:9px}
nav.menu .download-btn img{height:24px;min-width:24px}
nav.menu .offcanvas{border:none;z-index:2}
nav.menu .offcanvas .logo{height:80px;display:inline-block;padding:4px 4px 0 15px;position:sticky;top:0;background:var(--thm1)}
nav.menu .offcanvas .logo img{width:auto;height:100%}
nav.menu .LastH{display:flex;padding:0;z-index:3;justify-content:flex-end;position:relative;align-items:center;transition:all .5s}
nav.menu .LastH .dropdown-menu{left:auto!important;right:0!important}
nav.menu .LastH .afterLogin,nav.menu .LastH .Logout{display:none!important}
nav.menu .LastH .IconImg{text-align:center;padding:0;height:auto;display:grid;place-items:center;margin-left:30px}
nav.menu .LastH .IconImg.btn{line-height:45px;display:flex;margin-left:20px!important;margin-top:0!important}
nav.menu .LastH .IconImg.btn img{height:22px!important;width:22px!important;margin:0 9px 0 0!important}
nav.menu .LastH .IconImg:first-child{margin:0}
nav.menu .LastH .IconImg img{height:24px;width:24px;transition:all .5s}
nav.menu .LastH .dropdown-toggle{color:var(--black);line-height:normal}
nav.menu .LastH .Lag .dropdown-menu{min-width:50px}
nav .offcanvas .position-sticky{background:var(--thm);opacity:0;-ms-transform:translateY(100%);transform:translateY(100%);transition:all .6s}
nav ul{padding:0!important;width:100%}
nav ul.navbar-nav>li{margin-left:28px;opacity:0;-ms-transform:translateX(-50%);transform:translateX(-50%);transition:all .6s}
nav ul.navbar-nav>li:first-child{margin:0}
nav .offcanvas.show li,nav .offcanvas.show .position-sticky{transition:all .6s cubic-bezier(0.03,0.46,0.31,0.97);-ms-transform:translate(0%);transform:translate(0%);opacity:1}
nav .offcanvas.show .position-sticky{transition-delay:.6s}
nav .offcanvas.show li:nth-child(1){transition-delay:.1s}
nav .offcanvas.show li:nth-child(2){transition-delay:.2s}
nav .offcanvas.show li:nth-child(3){transition-delay:.3s}
nav .offcanvas.show li:nth-child(4){transition-delay:.4s}
nav .offcanvas.show li:nth-child(5){transition-delay:.5s}
nav .offcanvas.show li:nth-child(6){transition-delay:.6s}
nav .offcanvas.show li:nth-child(7){transition-delay:.7s}
nav .offcanvas.show li:nth-child(8){transition-delay:.8s}
nav .offcanvas.show li:nth-child(9){transition-delay:.9s}
nav .offcanvas.show li:nth-child(10){transition-delay:1s}

nav ul.navbar-nav>li>a{padding:15px 1px}
nav ul.navbar-nav>li>a,nav ul.navbar-nav>li>a span{color:var(--black)!important;margin-left:1px;text-transform:uppercase; transition:all .5s}
nav ul.navbar-nav>li>a span{position:relative;display:inline-block;padding:0!important;white-space:nowrap;font-weight:600}
nav ul li.dropdown{display:inherit}
nav ul>li.dropdown>a span{padding-right:1px!important}
nav ul>li>a.dropdown-toggle span:after{display:none}
nav ul.navbar-nav>li:after,nav ul.navbar-nav>li:after{content:'';display:none;position:absolute;top:0;left:0;right:0;margin:0 auto;width:0;height:3px;background:var(--thm);transition:all .6s}
/*nav ul>li.active>a,nav ul>li:hover>a{background:var(--thm1)!important}*/
nav ul>li.active>a,nav ul>li:hover>a,nav ul>li.active>a span,nav ul>li:hover>a span{color:var(--thm)!important}
nav ul.navbar-nav>li.active:after,nav ul.navbar-nav>li:hover:after{width:100%}
nav ul>li:hover>a.dropdown-toggle:after,nav ul>li>a.dropdown-toggle.show:after{transform:translateY(70%) rotate(135deg)}
nav ul.navbar-nav>li>a.active,nav ul.navbar-nav>li>a.active span{color:var(--thm)!important}
nav ul>li ul li a{margin:0!important;padding:6px 15px!important;display:block;white-space:nowrap;font-size:15px;width:100%;color:rgb(var(--blackrgb)/.9)!important;transition:all .5s}
nav ul>li ul li.active a,nav ul>li ul li:hover a{color:var(--thm)!important}
nav .MenuBar .Logout{display:none}

nav.menu .dropdown .dropdown-menu{transition:all .1s;/*background:var(--thm);*/right:auto;left:0;width:auto;transform:translateY(5px);min-width:180px;margin:0!important;opacity:0;visibility:hidden;transform:rotateX(-90deg);transform-origin:0 0;padding:6px 0!important;border-radius:0 0 5px 5px;font-size:14px}
nav.menu .dropdown:hover .dropdown-menu,nav.menu .dropdown .dropdown-menu.show{opacity:1;transform:translateY(0px);visibility:visible}
nav.menu .dropdown .dropdown-menu.show{opacity:1;transform:translateY(0px);visibility:visible}
nav.navbar.menu .dropdown .dropdown-menu,nav.navbar.menu .dropdown:hover .dropdown-menu .dropdown-menu{transition:all .5s;border:none;/*opacity:0;visibility:hidden;*/padding:0;border-radius:5px;font-size:14px;box-shadow:0 2px 15px rgb(var(--blackrgb)/.3)}
nav.navbar.menu .dropdown>a:before{position:absolute;bottom:0;border-left:7px solid transparent;border-right:7px solid transparent;border-bottom:7px solid var(--white);height:0;width:0;right:0;left:0;margin:0 auto;content:"";opacity:0;visibility:hidden;z-index:1111;transform:rotate(0deg);transition:all .4s}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a:before{}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a{display:flex;justify-content:space-between}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a:after{transform:rotate(-90deg)}
nav.navbar.menu .dropdown:hover>a:before,nav.navbar.menu .dropdown>a.show:before{opacity:1;visibility:visible}
nav.navbar.menu .dropdown .dropdown-menu .dropdown-item{padding:.3rem 1rem;font-size:14px}
nav.navbar.menu .dropdown .dropdown-menu li{border-bottom:1px solid rgb(var(--blackrgb)/.07);margin:0}
nav.navbar.menu .dropdown .dropdown-menu li img{height:22px;width:22px;margin-right:6px}
nav.navbar.menu .dropdown .dropdown-menu li:hover>a,nav.navbar.menu .dropdown .dropdown-menu li:hover>a span{color:var(--thm)!important;background:var(--white)!important}
nav.navbar.menu .dropdown .dropdown-menu li:last-child{border:none}
nav.navbar.menu .dropdown .dropdown-menu .dropdown-menu{left:99%;top:1%}
nav.navbar.menu .dropdown:hover .dropdown-menu,nav.navbar.menu .dropdown .dropdown:hover .dropdown-menu{display:block;opacity:1;visibility:visible}
nav.navbar.menu .dropdown .dropdown-menu .dropdown-item.active,nav.navbar.menu .dropdown .dropdown-menu .dropdown-item:hover{color:var(--thm)}
nav.navbar.menu .dropdown .dropdown-menu .dropdown-item.active,nav.navbar.menu .dropdown .dropdown-menu .dropdown-item:active{background:none}
nav.navbar.menu .dropdown.Megamenu{position:static}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu{width:100%;background:none;border:none;transform:translateY(5px);transition:all .1s}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu>div{background:rgb(var(--whitergb)/.95);border-radius:0 0 5px 5px;box-shadow:0 3px 5px rgb(var(--blackrgb)/.1);padding:20px;max-height:calc(100vh - 225px);overflow:auto}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu>div .row{-webkit-column-count:5;-moz-column-count:5;column-count:5;display:block;-webkit-column-gap:1.25rem;-moz-column-gap:1.25rem;column-gap:1.25rem}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu>div .row>div{display:block}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu>div .row>div ul{display:inline-block;margin-bottom:9px}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu ul li{display:block;border:none;margin:0 0 5px;line-height:normal!important;transition:all .5s}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu ul li a{background:none;border:none;font-weight:400;padding:2px 0!important;font-size:13px;display:inline-block;transition:all .5s}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu ul li:hover a{transform:translateX(4px)}
nav.navbar.menu .dropdown.Megamenu .dropdown-menu ul li.title a{transform:translateX(0)}
nav.navbar.menu .dropdown.Megamenu:hover .dropdown-menu{transform:translateY(0px)}


@-webkit-keyframes fadeInDown{0%{opacity:0;-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0)}
100%{opacity:1;-webkit-transform:none;transform:none}}
@keyframes fadeInDown{0%{opacity:0;-webkit-transform:translate3d(0,-100%,0);-ms-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0)}
100%{opacity:1;-webkit-transform:none;-ms-transform:none;transform:none}}
.fadeInDown{-webkit-animation-name:fadeInDown;animation-name:fadeInDown}
nav.menu.is-fixed>.st{position:fixed;left:0;top:0;background:rgb(var(--whitergb)/.9);backdrop-filter:blur(8px);width:100%;box-shadow:0 0 8px rgb(var(--blackrgb)/.2);z-index:99;padding:0;-webkit-animation-duration:.8s;animation-duration:.8s;-webkit-animation-name:fadeInDown;animation-name:fadeInDown;-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}
/*nav.menu.is-fixed .logom{max-width:195px}*/
nav.menu.is-fixed .logom .logo{margin:0;padding:3px;max-width:72px;}
/* nav.menu.is-fixed ul.navbar-nav>li>a,nav.menu.is-fixed ul.navbar-nav>li>a span{color:var(--black)!important} */
/* nav.menu.is-fixed ul.navbar-nav>li:hover>a,nav.menu.is-fixed ul.navbar-nav>li:hover>a span{color:var(--black)!important} */
nav.menu.is-fixed .notification>a .num{background:var(--black);color:var(--white)}

@media only screen and (min-width:992px){nav .logom{max-width:28%}
nav .MenuBar{z-index:4}
/*nav .MenuBar,nav .LastH{max-width:calc(50% - 150px)}*/
.offcanvas-backdrop.show{opacity:0;z-index:-1}
nav.menu .dropdown .dropdown-menu{display:block}
nav.menu .offcanvas{height:auto!important;visibility:visible;flex-direction:row;display:flex;position:static;transform:translateX(0);width:100%;background:none}
nav ul.navbar-nav{flex-direction:row;justify-content:end}
nav ul.navbar-nav>li{margin-bottom:0;opacity:1;transform:translateX(0)}
nav.menu .dropdown-menu{position:absolute;top:100%;z-index:1000;text-align:left;list-style:none;background-color:var(--white);background-clip:padding-box}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a:before{transform:rotate(-90deg);left:auto;right:-3px;top:15px}
.navbar form{position:absolute!important;right:200px;width:calc(100% - 275px);padding:0!important;top:0;bottom:0;background:var(--white);z-index:4}
/*.navbar form.collapse-horizontal.collapsing{width:0}*/
.navbar form .Dsearch{position:absolute;right:15px;top:0;bottom:0;display:grid;place-content:center;opacity:0;transition:all .5s}
.navbar form.show .Dsearch{opacity:1}
/*.Dsearch:not(.collapsed) img{display:none}
.Dsearch:not(.collapsed) i{display:block}*/
.Dsearch.collapsed img{display:block}
.Dsearch.collapsed i{display:none;font-size:24px}
.Dsearch i{font-size:24px}
.navbar form .form-control{font-size:15px!important;border-radius:5px;height:41px!important;padding-left:40px!important;background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%23666" viewBox="0 0 388.89 364.98"><path d="M385.64,348.54l-91.79-91.12a162.53,162.53,0,1,0-12,14.73l91,90.37a8.54,8.54,0,0,0,12-.5l1.35-1.47A8.52,8.52,0,0,0,385.64,348.54ZM162.31,305.2A142.89,142.89,0,1,1,305.2,162.31h0A142.88,142.88,0,0,1,162.33,305.2Z"/></svg>');background-repeat:no-repeat;background-size:20px;background-position:9px}
.navbar form button{display:none!important}}
@media only screen and (min-width:1600px){nav .logom{max-width:28%}
nav.menu .download-btn{margin-left:1%}}
@media (max-width:991px){/*body>main{margin-top:-90px}*/
nav.menu .MenuIcon:not(.offcanvasClose)+.logo img,nav.menu .offcanvas.show+.LastH img{filter:inherit}
nav.menu .MenuIcon:not(.offcanvasClose) svg{fill:var(--thm)!important}
nav.menu .dropdown .dropdown-menu.show{opacity:1;transform:translateY(0px);visibility:visible}
nav ul.navbar-nav>li:after{display:none!important}
nav ul.navbar-nav>li>a,nav ul.navbar-nav>li>a span{font-size:18px;padding:3px 0!important;color:rgb(var(--blackrgb)/.7)!important}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a{justify-content:center}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a:after{transform:rotate(180deg)}
nav .MenuBar{max-width:calc(100% - 210px);justify-content:end}
nav.navbar.menu .dropdown:hover .dropdown-menu,nav.navbar.menu .dropdown:hover>a:before{display:none;opacity:0;visibility:hidden}
nav.navbar.menu .dropdown:hover .dropdown-menu.show,nav.navbar.menu .dropdown:hover>a.show:before{display:block;opacity:1;visibility:visible}
nav ul.navbar-nav>li{margin:12px 0}
nav ul>li ul li{margin-bottom:5px;text-align:center}
nav ul>li ul li a{font-size:18px;color:var(--white);padding:8px 15px!important;overflow:hidden;text-overflow:ellipsis;display:inherit;}
nav ul li.dropdown{flex-direction:column}
nav ul li.dropdown ul{background:none}
nav.navbar.menu .dropdown:hover .dropdown-menu .dropdown .dropdown-menu,nav.navbar.menu .dropdown .dropdown-menu.show .dropdown .dropdown-menu{display:block;opacity:1;visibility:visible;transform:rotateX(0)}
nav.navbar.menu .dropdown .dropdown-menu .dropdown>a:before{display:none}
nav.menu .dropdown .dropdown-menu{background:rgb(var(--thmrgb1)/.2)}
nav.navbar.menu .dropdown>a:before{border-bottom-color:rgb(var(--thmrgb1)/.2)}
nav.menu .offcanvas{height:100vh;background:rgb(var(--whitergb)/.95);backdrop-filter:blur(9px)}
nav.menu .offcanvas .navbar-nav{height:calc(100% - 175px);margin-top:80px;/*align-items:center;*/ text-align:center;overflow:auto}
nav ul.navbar-nav>li>a.dropdown-toggle{text-align:center;position:relative}
nav.menu{padding:0;height:80px;}
nav .logom{max-width:130px}
nav.menu .MenuIcon{max-width:inherit}
nav.menu.is-fixed>.st{background:rgb(var(--thmrgb)/.9)}
nav.menu.is-fixed ul.navbar-nav>li>a,nav.menu.is-fixed ul.navbar-nav>li>a span{color:var(--black)!important}
nav.menu.is-fixed ul.navbar-nav>li:hover>a,nav.menu.is-fixed ul.navbar-nav>li:hover>a span,nav.menu.is-fixed ul.navbar-nav>li.active>a,nav.menu.is-fixed ul.navbar-nav>li.active>a span{color:rgb(var(--blackrgb)/.7)!important}
nav.menu.is-fixed .logom{max-width:190px}
nav.menu.is-fixed .logom .logo{max-width:65px;}
nav.menu.is-fixed .offcanvas .navbar-nav{margin-top:70px}
nav.menu.is-fixed .MenuIcon,nav.menu.is-fixed .LastH .IconImg{height:55px}}
@media (max-width:767px){nav.menu .LastH .SlideMenu .navbar-nav{flex-direction:column}}

.inner-banner{position:relative;overflow:hidden;padding:0;z-index:1;background:linear-gradient(90deg,var(--thm) 31%,transparent 31%)}
.inner-banner .section{background:linear-gradient(90deg,var(--thm) 30.75%,transparent 30.75%)}
@media (max-width:1200px){.inner-banner{background:linear-gradient(90deg,var(--thm) 29%,transparent 29%)}
.inner-banner .section{background:linear-gradient(90deg,var(--thm) 32%,transparent 32%)}}
.inner-banner>.bgimg img{width:100%;height:100%;object-fit:cover;position:absolute;left:0;top:0;z-index:-1}
.inner-banner h1{color:rgb(var(--whitergb)/.8)!important;font-weight:600}
.inner-banner .section{padding:60px 0 9px;display:flex;align-items:flex-end;text-align:center;min-height:110px}
.inner-banner .section>div{position:relative;z-index:1}
.inner-banner .breadcrumb{margin-bottom:0;justify-content:center;flex-wrap:nowrap}

.breadcrumb{justify-content:center}
.breadcrumb a,.breadcrumb span{color:rgb(var(--whitergb)/.5)!important;font-size:13px;line-height:normal}
.breadcrumb li:last-child a,.breadcrumb li:last-child span{color:var(--white)!important}
.breadcrumb li:first-child a{color:var(--thm)!important}
.breadcrumb .breadcrumb-item{display:flex;align-items:center}
.breadcrumb .breadcrumb-item img,.breadcrumb .breadcrumb-item svg{vertical-align:top}
.breadcrumb .breadcrumb-item:last-child{color:var(--white)!important;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:block}
.breadcrumb-item+.breadcrumb-item:before{content:var(--bs-breadcrumb-divider,"/");/*margin-top:2px;*/color:rgb(var(--whitergb)/.3)}
@media only screen and (min-width:992px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){.inner-banner .section{min-height:80px;align-items:center;padding:9px 0}
.inner-banner h1{text-align:center;margin:0}
.breadcrumb-item+.breadcrumb-item{padding-left:3px}
.breadcrumb-item+.breadcrumb-item:before{padding-right:3px}
.inner-banner.new-prellax .section>div>.row{margin-top:4rem}}
@media only screen and (max-width:450px){}

.MyCarousel{overflow-x:auto;display:flex;scroll-snap-type:x mandatory;column-gap:24px;text-align:center;}
.MyCarousel.Move{cursor:grabbing}
.MyCarousel.Move a{pointer-events:none}
.MyCarousel~.PreNext{width:120px;display:flex;justify-content:space-between;margin:20px auto 0}
.MyCarousel~.PreNext .btn{position:static;height:40px;width:40px;display:grid;place-items:center;box-shadow:3px 0 10px rgb(var(--blackrgb)/.2);border-radius:50%;border:none;background:var(--thm);transition:all .5s}
.MyCarousel~.PreNext .btn:after{border:none;border-bottom:1px solid var(--white);border-left:1px solid var(--white);height:12px;width:12px;content:'';display:inline-block;transform:rotate(45deg) translate(2px, -2px)}
.MyCarousel~.PreNext .btn.next{transform:rotate(180deg)}
.MyCarousel .SerBlock{min-width:calc(20% - 20px);max-width:calc(20% - 20px)}
.card.SerBlock{border-radius:12px!important;border:none;margin:15px 0 0;height:100%;z-index:1;position:relative;overflow:inherit;/*box-shadow:0 4px 9px rgb(var(--blackrgb)/.2)!important;scroll-snap-align:start;*/transition:all .5s}
.SerBlock .card-header{height:auto!important;border:none;background:none}
.SerBlock .card-header>*{background:none!important}
.SerBlock>.card-header img{height:140px;width:140px!important;border-radius:50%;object-fit:cover;display:flex!important;margin:0 auto;}
.SerBlock>.card-header>div{position:absolute;height:100%;width:100%;left:0;top:0;z-index:-1;filter:blur(9px);background-size:cover!important;background-position:center!important}
.SerBlock .card-body{width:100%;background:var(--white)!important;line-height:normal;position:relative;background:#eee;transition:all .5s}
.SerBlock .card-body h3{color:var(--black);line-height:120%!important;transition:all .5s;font-size:15px;margin:0;display:-webkit-box;overflow:hidden;-webkit-box-orient:vertical;-webkit-line-clamp:1;font-weight:500}
.SerBlock .card-body p{display:-webkit-box;overflow:hidden;-webkit-box-orient:vertical;-webkit-line-clamp:3;margin:0}
.SerBlock .card-body .badge{text-transform:uppercase;font-weight:400;font-size:14px;color:var(--black);border-radius:12px;padding:6px 25px;position:absolute;left:15px;top:0;transform:translateY(-50%);transition:all .5s}
.SerBlock:hover{box-shadow:0 4px 9px rgb(var(--blackrgb)/.2)!important;background:var(--white);transform:translateY(-5px);}
.SerBlock:hover .card-body h3{color:var(--thm)}
.SerBlock:hover .card-body .badge{background:var(--thm)!important;color:var(--white)}
@media only screen and (max-width:991px){.card.SerBlock,.Sec4 .blog.MyCarousel .card{scroll-snap-align:start}}
@media only screen and (max-width:767px){.MyCarousel .SerBlock,.Sec4 .blog.MyCarousel .card{min-width:calc(50% - 13px)!important;max-width:calc(50% - 13px)!important}}
@media only screen and (max-width:575px){.MyCarousel .SerBlock,.Sec4 .blog.MyCarousel .card{min-width:260px!important;max-width:260px!important}}

@media only screen and (max-width:991px){.container{max-width:98%!important}
#scroll-top{bottom:62px!important;right:9px!important}
/*.ys:before,.ys:after,.ts:before,.bs:after{background-size:1365px}*/}
@media only screen and (max-width:767px){.container{max-width:100%!important}
body section{padding:60px 0}
body section.Home{padding:60px 0 0}
.CmsPage img.w-100{max-width:350px;width:100%;display:flex;margin:9px auto}}
@media only screen and (max-width:578px){body section{padding:50px 0}
body section.Home{padding:50px 0 0}}
@media only screen and (max-width:450px){}
@media only screen and (max-width:350px){}