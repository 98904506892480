.grayscale.item{-webkit-filter:grayscale(100%);filter:grayscale(100%);border-radius:0;border:none}
.grayscale.item p{line-height:150%}
.grayscale.item:hover{-webkit-filter:grayscale(0);filter:grayscale(0)}

@keyframes pulse{0%{-webkit-transform:scale(1);will-change:transform;transform:scale(1)}
50%{-webkit-transform:scale(1.08);will-change:transform;transform:scale(1.08)}
100%{-webkit-transform:scale(1);will-change:transform;transform:scale(1)}}

.card{transition:all .5s}
.star{color:var(--black);font-size:18px;line-height:normal;display:inline;margin:0 auto;position:relative} 
.star:before{content:'\2605\2605\2605\2605\2605';opacity:.9;color:var(--black)}
.star[data-title="0"]:after{width:0}
.star[data-title="1"]:after{width:20%}
.star[data-title="2"]:after{width:40%}
.star[data-title="3"]:after{width:60%}
.star[data-title="4"]:after{width:80%}
.star[data-title="5"]:after{width:100%}
.star:after{color:#e6af19;content:'\2605\2605\2605\2605\2605';/*text-shadow:0 1px 0 #ab5414;*/position:absolute;z-index:0;height:100%;left:0;overflow:hidden}
body.bg-dark .star:before{color:var(--white)}

.nav-tabs{padding:0;border-radius:30px;border:1px solid rgb(var(--blackrgb)/.08);margin:0 auto;display:inline-flex;justify-content:center;max-width:max-content;width:100%}
.nav-tabs .nav-link{position:relative;border:none;border-radius:30px;font-size:16px;color:var(--black)!important;background:none!important;font-weight:600}
.nav-tabs .nav-link>*{z-index:1;position:relative}
/*.nav-tabs .nav-link>span{padding:8px;display:block}*/
.nav-tabs .nav-link.active{background:var(--white)!important}
.tab-content .tab-pane{padding:25px}
.bgimg{background:var(--black)}
.bgimg img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.bgimg>div{position:relative;z-index:1}
@media (max-width:767px){/*.nav-tabs .nav-link{font-size:13px}*/
.nav-tabs .nav-link>span{padding:0}
.CmsPage img{display:flex;margin:0 auto 20px;}}
@media (max-width:575px){}
@media (max-width:450px){/*.nav-tabs .nav-link{padding:6px;font-size:12px}*/
.nav-tabs .nav-link>span img{height:18px;width:18px}}

.GetAQuotes .BuyBtnBox{display:flex;padding:30px;max-width:999px;margin:0 auto;align-items:center;justify-content:space-between;border:1px solid rgb(var(--blackrgb)/.1);border-radius:6px;background:rgb(var(--thmrgb)/.1);transition:all .5s}
.GetAQuotes .BuyBtnBox .btn{line-height:40px}
@media only screen and (max-width:1300px) and (min-width:767px){.GetAQuotes .BuyBtnBox>div:last-child{display:flex;flex-wrap:wrap;justify-content:flex-end}
.GetAQuotes .BuyBtnBox .btn{order:1}
.GetAQuotes .BuyBtnBox>div:last-child a:first-child{margin-top:9px!important;order:2}}
@media only screen and (max-width:768px){.GetAQuotes .BuyBtnBox{display:block;text-align:center}
img{max-width:100%;}
.GetAQuotes .BuyBtnBox>div:last-child a{margin:9px 0 0!important}}

.youtube-player{position:relative;height:100%;overflow:hidden;max-width:100%;background:#000}
.youtube-player iframe{position:absolute;top:0;left:0;width:100%;height:100%;z-index:100;background:transparent}
.youtube-player img{object-fit:cover;display:block;left:0;bottom:0;margin:auto;max-width:100%;width:100%;position:absolute;right:0;top:0;border:none;height:auto;cursor:pointer;-webkit-transition:0.4s all;-moz-transition:0.4s all;transition:0.4s all}
.youtube-player img:hover{-webkit-filter:brightness(75%)}
.youtube-player .play{height:72px;width:72px;left:50%;top:50%;transform:translateX(-50%) translatey(-50%);position:absolute;background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368.8 260.88"><path fill="%23f51c0d" d="M0,187.84V78.08S1.28.08,77.93.08,222.14,0,294.93,0,368.8,77.53,368.8,77.53l-.15,109.83s-2.07,73.44-74.56,73.44-142.83.1-216.06.1C1.09,260.88,0,187.84,0,187.84Zm133.85-10.22c0,6.4,2.25,7.84,8,5.12l96.3-45.92c4.82-1.76,5.09-6.51,0-9.62L142,78.5c-5.83-3-8.1-1.51-8.1,5.14C133.84,99.36,133.84,162,133.85,177.62Z"/><path fill="%23fff" d="M133.84,177.62c0,6.4,2.24,7.84,8,5.12l96.3-45.92c4.82-1.76,5.08-6.51,0-9.62L142,78.5c-5.83-3-8.1-1.51-8.1,5.14C133.83,99.36,133.83,162,133.84,177.62Z"/></svg>') center/4em auto no-repeat;cursor:pointer;z-index:2;filter:drop-shadow(2px 3px 0 rgb(var(--blackrgb)/.2))}
.playVideo{height:100%;width:100%;position:absolute;z-index:3;left:0;top:0}
/*@media (max-width:768px){.card.ProBox{height:250px!important}}*/

.CmsPage h1.Heading{margin-top:0!important}
.CmsPage h2,.CmsPage .h2{font-size:1.5rem;color:var(--thm)}
.CmsPage h3,.CmsPage .h3{font-size:1.35rem;color:var(--thm1);margin-top:24px!important}
.CmsPage h4,.CmsPage .h4{font-size:1.25rem;font-weight:600;margin-top:24px!important}
.CmsPage h5,.CmsPage .h5{font-size:1.1rem;color:var(--thm);font-weight:600;margin-top:20px!important}
.CmsPage h6,.CmsPage .h6{font-size:1rem;font-weight:600;margin-top:20px!important}
.CmsPage .Leftpanel ul:not(.browser-default)>li{list-style-type:none}
.CmsPage ul:not(.browser-default)>li{list-style-type:disc}
.CmsPage h2.m-0,.CmsPage h3.m-0,.CmsPage h4.m-0,.CmsPage h5.m-0,.CmsPage h6.m-0{margin:0!important}
.CmsPage h2.mt-0,.CmsPage h3.mt-0,.CmsPage h4.mt-0,.CmsPage h5.mt-0,.CmsPage h6.mt-0{margin-top:0!important}
.CmsPage h2.mb-0,.CmsPage h3.mb-0,.CmsPage h4.mb-0,.CmsPage h5.mb-0,.CmsPage h6.mb-0{margin-bottom:0!important}

.CountryCode a{border:1px solid #e1e1e1;display:flex;align-items:center;line-height:normal!important;background:var(--white)!important;padding:9px 25px;border-radius:25px 0 0 25px;height:100%}
.CountryCode a.dropdown-toggle:after{margin-top:-4px}
.CountryCode>a{height:auto}
/*.CountryCode a.show:before{position:absolute;content:'';right:0;left:0;margin:0 auto;bottom:-17px;z-index:9999;width:9px;height:9px;transform:rotate(45deg);background:#ddd}*/
.CountryCode a:after{font-size:18px}
.CountryCode a span{max-width:150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:block;font-size:18px;text-transform:uppercase}
.CountryCode a span:after{display:none}
.CountryCode .CountryCode{max-width:66px;text-align:center;padding:0!important}
.CountryCode .form-control,.CustomerInfo .form-control{height:calc(3rem + 2px);border-radius:0 25px 25px 0!important;font-size:18px;padding:0 20px}
.CountryCode .form-control:first-child,.CountryCode>span.d-none~.form-control,.CountryCode>span[style="display:none"]~.form-control,.CountryCode>span[style="display:none;"]~.form-control,.CountryCode>span[style="display:none"]~.form-control,.CountryCode>span[style="display:none;"]~.form-control{border-radius:30px!important}
.CustomerInfo .form-control{border-radius:25px!important}
.CountryCode .countrylist{padding:0;max-height:200px;overflow:auto;background:var(--white);/*right:auto!important;left:0!important;*/box-shadow:0 0 25px rgb(var(--blackrgb)/.2);border-color:rgb(var(--blackrgb)/.05);border-radius:9px;margin-top:9px!important;border:none}
.countrylist li{padding:5px 12px;cursor:pointer;font-size:14px;padding-right:70px;white-space:nowrap}
.countrylist li:first-child{padding:5px 12px!important;background:#eee!important;display:block!important;position:sticky;top:0;z-index:2}
.countrylist li:first-child .form-control{height:calc(2rem + 2px);border-radius:6px!important;font-size:14px;padding:0 9px}
.countrylist li i{margin-right:5px}
.countrylist li span{font-size:12px;color:rgb(var(--blackrgb)/.5);position:absolute;right:12px}
.countrylist li:hover{background:rgb(var(--blackrgb)/.08)}

.accordion-item{margin-bottom:9px;border:none}
.accordion-header{overflow:hidden;background:rgb(var(--thmrgb)/.08)}
.accordion-header .accordion-button{border:none;padding:12px 20px;font-weight:400;background:rgb(var(--thmrgb)/.8);color:var(--white);box-shadow:none!important;border-radius:9px!important}
.accordion-header.thm{background:rgb(var(--thmrgb)/.5)}
.accordion-header .accordion-button img{height:30px;width:30px;object-fit:contain;margin-right:15px}
.accordion-header .accordion-button:hover{background:rgb(var(--thmrgb)/.9)}
.accordion-header .accordion-button:not(.collapsed){background:var(--thm);color:var(--white)}
.accordion-header .accordion-button:not(.collapsed) img{filter:inherit!important}
.accordion-header .accordion-button:after{background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.67 13.67" fill="%23fff"><path d="M1.52,7.65h13a.35.35,0,0,1,0,.7h-13A.35.35,0,1,1,1.52,7.65Z" transform="translate(-1.16 -1.16)"/><path d="M7.65,14.48v-13a.35.35,0,0,1,.7,0v13A.35.35,0,1,1,7.65,14.48Z" transform="translate(-1.16 -1.16)"/></svg>') 0 3px/15px no-repeat}
.accordion-header .accordion-button:not(.collapsed)::after{background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 13.67 13.67"><path d="M0.4,6h13c0.2,0,0.4,0.2,0.4,0.3c0,0.2-0.2,0.4-0.4,0.4h-13C0.2,6.7,0,6.5,0,6.3C0,6.2,0.2,6,0.4,6z"/></svg>') 0 3px/15px no-repeat}
.accordion-button:after{width:15px;background-size:15px;background-position:center!important}
.accordion-item:last-child{margin-bottom:0}
.accordion-body{background:var(--white)}
.accordion-body *{color:var(--black)!important}
@media only screen and (max-width:768px){.accordion-header .accordion-button{padding-left:20px;font-weight:400;font-size:14px;padding:9px 20px;height:auto;min-height:40px}
.accordion-button:after{height:15px;background-size:15px}
.accordion-header .accordion-button img{height:24px;width:24px;margin-right:9px}}

.form-check.form-switch .form-check-input:checked{background-color:var(--thm);border-color:var(--thm)}

#scroll-top{position:fixed;right:15px;bottom:30px;width:40px;height:40px;background:rgb(var(--whitergb)/.1);color:var(--thm);box-shadow:0 5px 5px rgb(var(--blackrgb)/.4);font-size:24px;z-index:99;line-height:33px;text-align:center;display:none;border-radius:100%;cursor:pointer}

.modal .modal-header .btn-close{position:absolute;right:-8px;top:-8px;height:30px;width:30px;border-radius:50%;background-color:#eee;opacity:1;z-index:2}
.SendInquiry .modal-header{border:none;padding:0}
.SendInquiry .modal-body form{border:none}
.SendInquiry .modal-body form .form-control{font-size:14px;background:rgb(var(--thmrgb)/.05)}
.SendInquiry .modal-body form .form-check>label{font-size:12px;color:rgb(var(--blackrgb)/.7)}
.SendInquiry .modal-body form .form-check .form-check-input[type=checkbox]{margin-top:0!important}

.form-check:not(.form-switch){display:flex;align-items:flex-start;padding:0}
.form-check:not(.form-switch) .form-check-input{margin:4px 8px 0 0!important;position:relative}
.form-check:not(.form-switch) .form-check-input:checked[type=checkbox]{background-image:none;background-color:initial;border-color:var(--thm)}
.form-check:not(.form-switch) .form-check-input:focus{box-shadow:0 0 0 .25rem rgb(var(--thmrgb)/.2)}
.form-check:not(.form-switch) .form-check-input[type=checkbox]{min-width:1em}
.form-check:not(.form-switch) .form-check-input[type=checkbox]:after{content:'';position:absolute;left:0;top:-3px;height:18px;width:18px;transform:scale(0);background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 9l4 4l11-12'/%3e%3c/svg%3e");transition:all .2s}
.form-check:not(.form-switch) .form-check-input:checked[type=checkbox]:after{transform:scale(1)}
.form-check:not(.form-switch)>label{line-height:150%;width:calc(100% - 1rem);font-size:14px}
.form-check:not(.form-switch) .form-check-input[type=radio]{min-width:1rem;min-height:1rem;background-color:rgb(var(--whitergb)/.4)}
.form-check:not(.form-switch) .form-check-input:checked[type=radio]{min-width:1rem;min-height:1rem;background-color:rgb(var(--whitergb)/.9);border-color:var(--thm1);background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.45 -1.45 3 3'%3e%3ccircle r='1' fill='%23000'/%3e%3c/svg%3e")}

.form-floating{color:rgb(var(--blackrgb)/.5)}
.form-floating>.form-control::placeholder{color:transparent!important}
.form-floating>.form-control:-ms-input-placeholder{color:transparent!important}
.form-floating>.form-control::-ms-input-placeholder{color:transparent!important}
.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown){padding-top:1rem;padding-bottom:.3rem;/* border-color:rgb(var(--blackrgb)/.4)!important;*/}
.form-floating>.form-control,.form-floating>.form-select{padding:.5rem .75rem .2rem;height:calc(2.5rem + 2px);border-color:rgb(var(--blackrgb)/.1);background-color:var(--white)}
.form-floating>.form-control[type="file"]:not(:placeholder-shown){padding-top:0;line-height:44px}
.form-floating>.form-control[type="file"]:not(:placeholder-shown)~label{transform:none;background:rgb(217 38 28);height:100%;left:auto;right:0;color:var(--white);opacity:1;border-radius:0 5px 5px 0}
.form-floating>.form-control~label:after{position:absolute;content:'';left:0;top:0;right:0;bottom:0;margin:auto;z-index:-1;width:90%;height:90%;/*background:var(--white);*/ opacity:1;transition:all .5s}
.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label{transform:scale(.8) translateY(-.1rem) translateX(.15rem);margin:0;padding-top:0;padding-bottom:0;height:auto;opacity:1;color:rgb(var(--blackrgb)/.6);z-index:3}
.form-floating>.form-select~label{transform:scale(.8) translateY(-.75rem) translateX(.15rem)}
.form-floating>.form-control:focus~label:after,.form-floating>.form-control:not(:placeholder-shown)~label:after,.form-floating>.form-select~label:after{opacity:1}
.form-floating>label{padding:.5rem .75rem}
.form-floating>textarea{height:140px!important}
.flatpickr,[type="date"]::-webkit-calendar-picker-indicator{background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.02 384"><path d="M876,266.25v267a9.15,9.15,0,0,0-.64,1.69c-3.36,18.5-13.83,31.31-31.13,38.45-3.73,1.54-7.81,2.26-11.73,3.36h-297a14.48,14.48,0,0,0-2.08-.69A49.34,49.34,0,0,1,492,527.58q-.12-127.86,0-255.7c0-21.07,13.53-39.47,33.45-46.47,8.72-3.07,17.7-2.65,26.68-2.66H576c0-5.1.27-9.84-.06-14.54-.53-7.35,2.19-12.56,9.06-15.46h6c6.82,2.94,9.61,8.1,9.07,15.48-.35,4.78-.07,9.61-.07,14.32H768c0-4.91.27-9.64-.06-14.34-.53-7.36,2.2-12.56,9.06-15.46h6c6.8,2.93,9.6,8.08,9.06,15.44-.34,4.79-.06,9.61-.06,14.57,12,0,23.5-.08,35,0a49.48,49.48,0,0,1,47.24,36.3C874.87,261.45,875.4,263.86,876,266.25ZM516,324.93v3.81q0,98.45,0,196.87c0,16.43,10.77,27.13,27.3,27.14H824.55c16.74,0,27.44-10.67,27.45-27.38q0-98.24,0-196.49v-3.95Zm84-78c0,3.8,0,7.17,0,10.54,0,7.89-5,13.35-12.13,13.28-6.93-.08-11.82-5.49-11.87-13.17,0-3.56,0-7.13,0-10.82-11.82,0-23.06-.06-34.3,0-12.82.1-23.7,8.56-25.07,21.27-1.16,10.69-.24,21.61-.24,32.49H852c0-9.32,0-18.42,0-27.52-.1-15.24-11-26.17-26.29-26.25-10.12,0-20.24,0-30.36,0-1.07,0-2.14.14-3.34.22,0,3.83,0,7.33,0,10.82-.07,7.58-5.13,13-12.08,12.94s-11.86-5.47-11.91-13.13c0-3.56,0-7.13,0-10.69Z" transform="translate(-491.98 -192.75)"/></svg>') right 1rem center/18px auto no-repeat}
[type="date"]::-webkit-calendar-picker-indicator{width:40px;height:20px}
input[type="date"]::-webkit-datetime-edit,input[type="date"]::-webkit-inner-spin-button,input[type="date"]::-webkit-clear-button{position:relative;color:transparent}
input[type="date"]::-webkit-datetime-edit-text{text-transform:uppercase}
input[type="date"]::-webkit-datetime-edit-year-field{position:absolute!important;left:57px;text-transform:uppercase;padding-left:5px;color:rgb(var(--blackrgb)/.8)}
input[type="date"]::-webkit-datetime-edit-month-field{position:absolute!important;left:24px;text-transform:uppercase;padding-left:5px;text-align:center;color:rgb(var(--blackrgb)/.8)}
input[type="date"]::-webkit-datetime-edit-day-field{position:absolute!important;left:0;text-transform:uppercase;color:rgb(var(--blackrgb)/.8)}
.flatpickr-day.selected{border-color:var(--thm)!important;background:var(--thm)!important}
.select2-container--default .select2-selection--single{border:1px solid rgb(var(--blackrgb)/.1)!important;height:auto!important}
.select2-container--default .select2-selection--single .select2-selection__arrow{height:100%;width:30px}
.select2-container--default .select2-selection--single .select2-selection__rendered{padding:.5rem .75rem .5rem}

.chosen-container .chosen-single{border:1px solid #ddd!important;border-radius:0!important;box-shadow:none!important;height:34px!important;padding:5px!important;color:var(--black)!important;background:none!important}
.chosen-container .chosen-single span{font-size:14px!important;margin-right:15px!important;line-height:normal!important}
.chosen-container .chosen-single div b{background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right .3rem center!important;background-size:16px 12px!important}
.chosen-container .chosen-drop{border-radius:0!important;border:1px solid #ddd!important;border-top:none!important;margin:0!important}
.chosen-container .chosen-search input[type="text"]{border:1px solid #ddd!important;padding:7px 25px 7px 9px!important;background:no-repeat right .2rem center url('data:image/svg+xml,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><g class="search-path" fill="none" stroke="%23555"><path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>')!important;background-size:18px!important}
.chosen-container .chosen-results{padding:0!important;margin:0!important}
.chosen-container .chosen-results li{padding:2px 8px!important;font-size:13px}
.chosen-container .chosen-results li.highlighted{background:var(--thm1)!important;color:var(--white)!important}
.chosen-container .chosen-results .active-result:first-child{display:none!important}
.chosen-container-multi .chosen-choices{display:flex!important;flex-wrap:wrap!important;padding:1px 5px;background-image:none!important;box-shadow:none!important;min-height:34px!important;border:1px solid #ddd!important}
.chosen-container-multi .chosen-choices li{border:none!important;background:none!important;padding:0!important}
.chosen-container-multi .chosen-choices li span{line-height:120%!important;background:var(--thm1)!important;border:none!important;border-radius:15px!important;color:var(--white)!important;padding:4px 9px;font-size:13px;padding-right:25px}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close{background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center no-repeat!important;background-size:8px!important;opacity:.5!important;top:6px!important;right:6px!important}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover{opacity:1!important}
.chosen-container-multi .chosen-drop .result-selected{background:rgb(var(--thmrgb1)/.1)!important}
.chosen-container-multi .chosen-drop .group-result{background:var(--thm)!important;color:var(--white)!important}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){border-color:#ddd!important}

.form-floating .chosen-container .chosen-single{border-radius:4px!important;height:46px!important}
.form-floating .chosen-container .chosen-single span{margin-right:20px!important;margin:6px 20px 0 9px !important;line-height:35px!important;font-size:16px!important}


footer .ConInfo li{color:rgb(var(--whitergb)/.8)!important;font-size:14px;margin-bottom:15px}
footer .ConInfo li a{color:rgb(var(--whitergb)/.8)!important}
footer .ConInfo li a:hover{color:var(--white)!important}

.Contact>svg{position:absolute;width:100%;bottom:0;left:0;z-index:-1}
.Contact .Bimg{margin-top:-300px;display:flex;justify-content:space-between;align-items:flex-end}
.Contact .img1,.Contact .img2{position:relative;vertical-align:bottom;height:280px}
.Contact .img2{right:0;height:380px}
.Contact form{border-radius:0;padding:30px;width:100%!important;box-shadow:0 0 25px rgb(var(--blackrgb)/.2);border-radius:9px}
.Contact .form-floating>.form-control:focus,.Contact .form-floating>.form-control:not(:placeholder-shown){padding-top:1rem}
.Contact .form-floating>.form-control,.Contact .form-floating>.form-select,.form-control,.form-select{/*padding:.8rem 1.5rem .2rem;*/height:calc(2.3rem + 2px);border-color:rgb(var(--blackrgb)/.09);background-color:rgb(var(--whitergb)/.9);border-radius:0}
.Contact form label.form-label{font-size:15px}
@media (max-width:1200px) and (min-width:992px){.Contact form label.form-label{font-size:11px}}
@media (max-width:992px){.Contact .col-lg-4>img,.Contact .col-lg-4>picture>img{max-width:450px;display:flex;margin:0 auto;}}
.Contact .form-floating>.form-control[type="file"]:not(:placeholder-shown){padding-top:0;line-height:44px}
.Contact .form-floating>.form-control[type="file"]:not(:placeholder-shown)~label{transform:none;height:100%;color:var(--white)!important;background:var(--thm1);left:auto;right:0;color:var(--white);opacity:1;border-radius:0 5px 5px 0}
.Contact .form-floating>label{padding:.85rem 1.5rem;color:rgb(var(--blackrgb)/.6)!important}
.Contact .form-floating>textarea,.Contact textarea.form-control{height:120px!important;resize:none}
.form-select:focus,.form-control:focus,.input-group .btn:focus,.input-group .btn:hover{box-shadow:0 0 0 .25rem rgb(var(--thmrgb)/.25);border-color:var(--thm)!important}
.input-group .btn.show{border-color:var(--thm)!important}
.form-control:focus{box-shadow:0 0 0 0.25rem rgb(var(--thmrgb)/.25);border-color:var(--thm)}
.ConInfo{margin-bottom:40px;padding:20px;border-radius:9px}
.ConInfo>div{background:rgb(var(--thmrgb)/.06)}
.ConInfo ul{margin:0;padding:0}
.ConInfo li{display:flex;align-items:baseline;font-size:16px;margin-bottom:15px;color:var(--white)!important}
.ConInfo li i,.ConInfo li img{height:24px;width:24px;position:relative;bottom:-6px;top:auto;margin-right:15px}
.ConInfo li a{color:var(--white)!important}
.ConInfo li a:hover{color:var(--white)!important}

.PopupM .modal-content{border-radius:15px!important}
.PopupM .form-floating .form-control{border-radius:9px}
.PopupM .form-floating .form-control[type=file]{border:none!important;padding:0!important}
.PopupM .form-floating .form-control[type=file]::file-selector-button{border-radius:9px!important;border:none!important;margin:0 9px 0 0!important;line-height:100%;height:100%;padding:0 20px}

.FootTop ul{padding:0;margin:0 0 20px;display:flex;flex-wrap:wrap;grid-gap:5px 30px}
.FootTop ul li{font-size:15px;position:relative;color:rgb(var(--blackrgb)/.9)!important}
.FootTop ul li:after{height:55%;width:1px;right:-15px;top:0;bottom:0;margin:auto auto 4px;content:'';background:rgb(var(--blackrgb)/.5);position:absolute}
.FootTop ul li:last-child:after{display:none}

footer .FooterTop{border-radius:60px;padding:20px 32px;position:relative;box-shadow:0 0 15px rgb(var(--blackrgb)/.1);transform:translateY(-50%);border:1px solid rgb(var(--blackrgb)/.1);}
footer .FooterTop .input-group .form-control{border-radius:30px;border-right:none;height:60px;font-size:18px;padding-left:30px;padding-right:40px;margin-right:-30px;z-index:3;}
footer .FooterTop .input-group .input-group-text{border-radius:30px!important;background:var(--thm1);padding:0 20px;color:var(--white);font-size:18px;z-index:3;cursor:pointer;position:relative;border-left:none}

footer{padding:0!important;width:100%;position:relative;background:rgb(var(--blackrgb)/.15);margin-top:50px}
footer .flogo{max-width:150px;width:100%;height:auto;}
footer h3{text-transform:uppercase}
/*footer p{font-size:15px}*/
footer ul.links{margin:0 0 30px;padding:0}
footer ul.links li{margin-bottom:9px}
footer ul.links li a{line-height:normal;padding:0;display:inline-block;position:relative;font-weight:400;color:var(--black);font-size:14px}
footer ul.links li a:hover{margin-left:9px;opacity:1!important}
    
footer .icons,.icons{display:flex;flex-wrap:wrap;justify-content:center;align-items:center}
footer .icons li,.icons li{display:inline-block;margin:0 5px}
footer .icons li{margin:0 3px;line-height:0!important}
footer .icons li:first-child,.icons li:first-child{margin-left:0}
footer .icons li a,.icons li a{display:inline-block;opacity:.8}
footer .icons li a img,footer .icons li a i,.icons li a img,.icons li a i{height:24px;width:24px;display:grid;place-items:center;transition:all .5s}
footer .icons li a:hover,.icons li a:hover{opacity:1}
footer .icons li a:hover i,.icons li a:hover i,.icons li a:hover img{transform:rotate(360deg)}
footer .icons li a:hover .fa,.icons{color:var(--white)}
footer .icons li a .fa-facebook,footer .icons li a .fa-facebook-f,.icons li a:hover .fa-facebook,.icons li a:hover .fa-facebook-f{background:#3b5998}
footer .icons li a .fa-twitter,.icons li a:hover .fa-twitter{background:#00acee}
footer .icons li a .fa-youtube,.icons li a:hover .fa-youtube{background:#F00}
footer .icons li a .fa-linkedin,.icons li a:hover .fa-linkedin,footer .icons li a .fa-linkedin-in,.icons li a:hover .fa-linkedin-in{background:#0e76a8}
footer .icons li a .fa-pinterest,footer .icons li a:hover .fa-pinterest-p,.icons li a .fa-pinterest,.icons li a:hover .fa-pinterest-p{background:#E60023}
footer .icons li a .fa-instagram,.icons li a:hover .fa-instagram{background:#f09433;background:-moz-linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);background:-webkit-linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);background:linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433',endColorstr='#bc1888',GradientType=1 )}

.fbottom{padding:15px 0;width:100%;border-top:1px solid rgb(var(--blackrgb)/.1);/*background:var(--black)*/}
.fbottom img{height:26px}
.fbottom p{margin:0;padding:0;color:var(--black)!important;font-weight:400}
.fbottom p strong,.fbottom p strong a{font-weight:600!important;color:var(--thm)!important;}
.fbottom p a:hover{opacity:1}
.fbottom .text-end p{text-align:right}
@media only screen and (min-width:991px){footer ul.links.ser{display:flex;flex-wrap:wrap;column-gap:20px;justify-content:space-between;}
footer ul.links.ser li{width:47%}}
/*@media only screen and (min-width:991px){footer>div>.row>div.col-md-2{width:20%}
footer>div>.row>div:first-child,footer>div>.row>div:last-child{width:30%}}*/
@media only screen and (max-width:991px){footer ul.links{display:flex;flex-wrap:wrap;column-gap:20px;justify-content:space-between;}
footer ul.links li{width:46%}
footer .text-end{text-align:center!important}
footer .text-start ul{justify-content:center;display:flex}}
@media only screen and (max-width:767px){.Contact form{padding:20px;}
.subscribeform .input-group{display:block;text-align:center;}
.subscribeform .input-group .form-control{width:100%;border-radius:30px!important;}
.subscribeform .input-group .input-group-text{display:inline-block;height:48px;margin-top:9px;}
footer{margin-top:120px;}
footer .FooterTop+div{margin-top:-60px;}
.fbottom,.fbottom .text-end p,.fbottom .text-start p{text-align:center!important}}
@media (max-width:575px){footer .Subscribe .input-group .input-group-text{padding:0 15px}
footer .Subscribe .input-group .form-control{padding-left:60px}.fbottom{padding:9px 0}}
@media only screen and (max-width:450px){}

[class*="sws-"]{position:relative;display:inline-block}
[class*="sws-"]:before,[class*="sws-"]:after{position:absolute;-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);transform:translate3d(0,0,0);visibility:hidden;opacity:0;z-index:1000000;pointer-events:none;-webkit-transition:.3s ease;-moz-transition:.3s ease;transition:.3s ease;-webkit-transition-delay:0ms;-moz-transition-delay:0ms;transition-delay:0ms}
[class*="sws-"]:hover:before,[class*="sws-"]:hover:after{visibility:visible;opacity:1}
[class*="sws-"]:hover:before,[class*="sws-"]:hover:after{-webkit-transition-delay:100ms;-moz-transition-delay:100ms;transition-delay:100ms}
[class*="sws-"]:before{content:'';position:absolute;background:transparent;border:6px solid transparent;z-index:1000001}
[class*="sws-"]:after{background:#383838;color:white;padding:6px 10px;font-size:12px;line-height:12px;white-space:nowrap}
[class*="sws-"][title]:after{content:attr(title)}
[class*="sws-"][data-title]:after{content:attr(data-title);font-family:'Montserrat', sans-serif!important}
[title='']:before,[title='']:after,[data-title='']:before,[data-title='']:after{display:none!important}
.sws-top-left:before{border-top-color:#383838}
.sws-top-right:before{border-top-color:#383838}
.sws-top:before{border-top-color:#383838}
.sws-bottom-left:before{border-bottom-color:#383838}
.sws-bottom-right:before{border-bottom-color:#383838}
.sws-bottom:before{border-bottom-color:#383838}
.sws-left:before{border-left-color:#383838}
.sws-right:before{border-right-color:#383838}
.sws-top:before{margin-bottom:-11px}
.sws-top:before,.sws-top:after{bottom:100%;left:50%}
.sws-top:before{left:calc(50% - 6px)}
.sws-top:after{-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);transform:translateX(-50%)}
.sws-top:hover:before{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-top:hover:after{-webkit-transform:translateX(-50%) translateY(-8px);-moz-transform:translateX(-50%) translateY(-8px);transform:translateX(-50%) translateY(-8px)}
.sws-bottom:before{margin-top:-11px}
.sws-bottom:before,.sws-bottom:after{top:100%;left:50%}
.sws-bottom:before{left:calc(50% - 6px)}
.sws-bottom:after{-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);transform:translateX(-50%)}
.sws-bottom:hover:before{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-bottom:hover:after{-webkit-transform:translateX(-50%) translateY(8px);-moz-transform:translateX(-50%) translateY(8px);transform:translateX(-50%) translateY(8px)}
.sws-right:before{margin-left:-11px;margin-bottom:-6px}
.sws-right:after{margin-bottom:-14px}
.sws-right:before,.sws-right:after{left:100%;bottom:50%}
.sws-right:hover:before{-webkit-transform:translateX(8px);-moz-transform:translateX(8px);transform:translateX(8px)}
.sws-right:hover:after{-webkit-transform:translateX(8px);-moz-transform:translateX(8px);transform:translateX(8px)}
.sws-left:before{margin-right:-11px;margin-bottom:-6px}
.sws-left:after{margin-bottom:-14px}
.sws-left:before,.sws-left:after{right:100%;bottom:50%}
.sws-left:hover:before{-webkit-transform:translateX(-8px);-moz-transform:translateX(-8px);transform:translateX(-8px)}
.sws-left:hover:after{-webkit-transform:translateX(-8px);-moz-transform:translateX(-8px);transform:translateX(-8px)}
.sws-top-left:before{margin-bottom:-11px}
.sws-top-left:before,.sws-top-left:after{bottom:100%;left:50%}
.sws-top-left:before{left:calc(50% - 6px)}
.sws-top-left:after{-webkit-transform:translateX(-100%);-moz-transform:translateX(-100%);transform:translateX(-100%)}
.sws-top-left:after{margin-left:12px}
.sws-top-left:hover:before{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-top-left:hover:after{-webkit-transform:translateX(-100%) translateY(-8px);-moz-transform:translateX(-100%) translateY(-8px);transform:translateX(-100%) translateY(-8px)}
.sws-top-right:before{margin-bottom:-11px}
.sws-top-right:before,.sws-top-right:after{bottom:100%;left:50%}
.sws-top-right:before{left:calc(50% - 6px)}
.sws-top-right:after{-webkit-transform:translateX(0);-moz-transform:translateX(0);transform:translateX(0)}
.sws-top-right:after{margin-left:-12px}
.sws-top-right:hover:before{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-top-right:hover:after{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-bottom-left:before{margin-top:-11px}
.sws-bottom-left:before,.sws-bottom-left:after{top:100%;left:50%}
.sws-bottom-left:before{left:calc(50% - 6px)}
.sws-bottom-left:after{-webkit-transform:translateX(-100%);-moz-transform:translateX(-100%);transform:translateX(-100%)}
.sws-bottom-left:after{margin-left:12px}
.sws-bottom-left:hover:before{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-bottom-left:hover:after{-webkit-transform:translateX(-100%) translateY(8px);-moz-transform:translateX(-100%) translateY(8px);transform:translateX(-100%) translateY(8px)}
.sws-bottom-right:before{margin-top:-11px}
.sws-bottom-right:before,.sws-bottom-right:after{top:100%;left:50%}
.sws-bottom-right:before{left:calc(50% - 6px)}
.sws-bottom-right:after{-webkit-transform:translateX(0);-moz-transform:translateX(0);transform:translateX(0)}
.sws-bottom-right:after{margin-left:-12px}
.sws-bottom-right:hover:before{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-bottom-right:hover:after{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-small:after,.sws-medium:after,.sws-large:after{white-space:normal;line-height:1.4em;word-wrap:break-word}
.sws-small:after{width:80px}
.sws-medium:after{width:150px}
.sws-large:after{width:300px}
[class*="sws-"]:after{text-shadow:0 -1px 0 black;box-shadow:4px 4px 8px rgba(0,0,0,0.3);border-radius:3px}
.sws-error:after{background-color:#b34e4d;text-shadow:0 -1px 0 #592726}
.sws-error.sws-top-left:before{border-top-color:#b34e4d}
.sws-error.sws-top-right:before{border-top-color:#b34e4d}
.sws-error.sws-top:before{border-top-color:#b34e4d}
.sws-error.sws-bottom-left:before{border-bottom-color:#b34e4d}
.sws-error.sws-bottom-right:before{border-bottom-color:#b34e4d}
.sws-error.sws-bottom:before{border-bottom-color:#b34e4d}
.sws-error.sws-left:before{border-left-color:#b34e4d}
.sws-error.sws-right:before{border-right-color:#b34e4d}
.sws-warning:after{background-color:#c09854;text-shadow:0 -1px 0 #6c5328}
.sws-warning.sws-top-left:before{border-top-color:#c09854}
.sws-warning.sws-top-right:before{border-top-color:#c09854}
.sws-warning.sws-top:before{border-top-color:#c09854}
.sws-warning.sws-bottom-left:before{border-bottom-color:#c09854}
.sws-warning.sws-bottom-right:before{border-bottom-color:#c09854}
.sws-warning.sws-bottom:before{border-bottom-color:#c09854}
.sws-warning.sws-left:before{border-left-color:#c09854}
.sws-warning.sws-right:before{border-right-color:#c09854}
.sws-info:after{background-color:#3986ac;text-shadow:0 -1px 0 #1a3c4d}
.sws-info.sws-top-left:before{border-top-color:#3986ac}
.sws-info.sws-top-right:before{border-top-color:#3986ac}
.sws-info.sws-top:before{border-top-color:#3986ac}
.sws-info.sws-bottom-left:before{border-bottom-color:#3986ac}
.sws-info.sws-bottom-right:before{border-bottom-color:#3986ac}
.sws-info.sws-bottom:before{border-bottom-color:#3986ac}
.sws-info.sws-left:before{border-left-color:#3986ac}
.sws-info.sws-right:before{border-right-color:#3986ac}
.sws-success:after{background-color:#458746;text-shadow:0 -1px 0 #1a321a}
.sws-success.sws-top-left:before{border-top-color:#458746}
.sws-success.sws-top-right:before{border-top-color:#458746}
.sws-success.sws-top:before{border-top-color:#458746}
.sws-success.sws-bottom-left:before{border-bottom-color:#458746}
.sws-success.sws-bottom-right:before{border-bottom-color:#458746}
.sws-success.sws-bottom:before{border-bottom-color:#458746}
.sws-success.sws-left:before{border-left-color:#458746}
.sws-success.sws-right:before{border-right-color:#458746}
.sws-always:after,.sws-always:before{opacity:1;visibility:visible}
.sws-always.sws-top:before{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-always.sws-top:after{-webkit-transform:translateX(-50%) translateY(-8px);-moz-transform:translateX(-50%) translateY(-8px);transform:translateX(-50%) translateY(-8px)}
.sws-always.sws-top-left:before{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-always.sws-top-left:after{-webkit-transform:translateX(-100%) translateY(-8px);-moz-transform:translateX(-100%) translateY(-8px);transform:translateX(-100%) translateY(-8px)}
.sws-always.sws-top-right:before{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-always.sws-top-right:after{-webkit-transform:translateY(-8px);-moz-transform:translateY(-8px);transform:translateY(-8px)}
.sws-always.sws-bottom:before{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-always.sws-bottom:after{-webkit-transform:translateX(-50%) translateY(8px);-moz-transform:translateX(-50%) translateY(8px);transform:translateX(-50%) translateY(8px)}
.sws-always.sws-bottom-left:before{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-always.sws-bottom-left:after{-webkit-transform:translateX(-100%) translateY(8px);-moz-transform:translateX(-100%) translateY(8px);transform:translateX(-100%) translateY(8px)}
.sws-always.sws-bottom-right:before{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-always.sws-bottom-right:after{-webkit-transform:translateY(8px);-moz-transform:translateY(8px);transform:translateY(8px)}
.sws-always.sws-left:before{-webkit-transform:translateX(-8px);-moz-transform:translateX(-8px);transform:translateX(-8px)}
.sws-always.sws-left:after{-webkit-transform:translateX(-8px);-moz-transform:translateX(-8px);transform:translateX(-8px)}
.sws-always.sws-right:before{-webkit-transform:translateX(8px);-moz-transform:translateX(8px);transform:translateX(8px)}
.sws-always.sws-right:after{-webkit-transform:translateX(8px);-moz-transform:translateX(8px);transform:translateX(8px)}
.sws-rounded:after{border-radius:4px}
.sws-no-animate:before,.sws-no-animate:after{-webkit-transition-duration:0ms;-moz-transition-duration:0ms;transition-duration:0ms}
.sws-bounce:before,.sws-bounce:after{-webkit-transition:opacity .3s ease,visibility .3s ease,-webkit-transform .3s cubic-bezier(0.71,1.7,0.77,1.24);-moz-transition:opacity .3s ease,visibility .3s ease,-moz-transform .3s cubic-bezier(0.71,1.7,0.77,1.24);transition:opacity .3s ease,visibility .3s ease,transform .3s cubic-bezier(0.71,1.7,0.77,1.24)}