.InnerHead{padding:90px 0}
.InnerHead img.imgbg{position:absolute;top:0;left:0;height:100%;width:100%;object-fit:cover;opacity:.2;z-index:0}
.InnerHead>div{position:relative;z-index:1}
.InnerHead>svg{position:absolute;bottom:0;left:0;z-index:1}

.Openings{border-radius:12px!important}
.Openings:hover{transform:translateY(-5px);box-shadow: 0 5px 20px rgb(var(--blackrgb)/.2)}

.ConHR{background:rgb(var(--thmrgb)/.15)!important;border:1px solid rgb(var(--thmrgb)/.1)!important;max-width:450px;width:100%;margin:30px auto 0;}
.ConHR>div{display:flex;align-items:center}
.ConHR>div .img{height:90px;width:90px;border-radius:50%;object-fit:contain;background:var(--white);box-shadow:0 4px 9px rgb(var(--blackrgb)/.2);overflow:hidden;}
.ConHR>div .img img{height:100%;width:100%;object-fit:contain;}
.ConHR>div>div:last-child{margin-left:20px}